<template>
    <div class="videovip">
        <div class="videovip-head">
            <img :src="data.goodsProps.bigIcon" alt="">
            <div>
                <p>{{ data.title }}</p>
                <p>{{ data.cost }}<img src="https://ppyos.xijiuyou.com/202205/msg-icon.jpg" alt=""></p>
            </div>
        </div>
        <div class="videovip-num">
            <p>充值账号</p>
            <div>
                <input v-model="account" type="number" placeholder="请输入开通账号对应手机号" />
                <img @click="account=''" v-if="account.length > 0" src="https://ppyos.xijiuyou.com/2401/video-close-icon.png" alt="">
            </div>
        </div>
        <div class="tips">
            <p>兑换说明</p>
            <div v-html="data.goodsProps.tips"></div>
        </div>
        <div class="zhif">
        <p>需支出 <span>{{data.cost}}</span> <img src="https://ppyos.xijiuyou.com/202205/msg-icon.jpg" alt=""></p>
        <p @click="succ">确认兑换</p>
        </div>
        <xwpopup :isshowpopup="voucher" @close="closevoucher()" :hiddenClose="false">
      <div class="voucher" v-if="data.goodsProps && data.prizeProps">
        <img src="https://ppyos.xijiuyou.com/202206/light-bg.jpg" alt="">
        <img :src="data.goodsProps.bigIcon" alt="">
        <p>您已成功兑换</p>
        <p>{{data.title}}</p>
        <p @click="closevoucher">好的</p>
      </div>
    </xwpopup>
    </div>
</template>
<script>
import { Toast } from "vant";
import caibei from "../../api/caibei";
import xwpopup from "../../components/Popupbox.vue";
    export default{
        components: {
    xwpopup,
  },
        data(){
            return{
                data:{
                    goodsProps:{},
                },
                account:"",
                voucher:false,
                checks:false,
            }
        },
        mounted(){
            this.data =JSON.parse(localStorage.getItem("videoVip"));
            console.log(this.data)
        },
        methods:{
            closevoucher(){
                this.voucher = !this.voucher;
            },
            succ(){
                if(!this.account){
                    Toast("请输入手机号");
                    return false;
                }
                if(this.account.length !== 11){
                    Toast("请正确输入手机号");
                    return false;
                }
                if(this.checks){
                    return false;
                }
                this.checks = true;
                caibei.conchbuyItem(this.data.goodId,this.account).then((res)=>{
                    console.log(res)
                    this.checks = false;
                    this.voucher = true;
                })
            },
        }
    }
</script>
<style lang="less" scoped>
    .videovip{
        .zhif{
      display: flex;
      justify-content: space-between;
      align-items: center;
      position: fixed;
      bottom: 0;
      left: 0;
      background: #FFFFFF;
      width: 100%;
      padding: 8px 12px;
      box-sizing: border-box;
      p:nth-child(1){
        font-size: 16px;
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        color: #333333;
        display: flex;
        align-items: center;
        span{
            font-size: 18px;
            font-family: DINOffcPro, DINOffcPro;
            font-weight: bold;
            color: #6781FF;
        }
        img{
          width: 24px;
          height: 24px;
          margin: 0;
          margin-left: 5px;
        }
      }
      p:nth-child(2){
        width: 147px;
        height: 48px;
        background: linear-gradient(270deg, #6AC8FF 0%, #D199FF 100%);
        box-shadow: inset 0px 2px 0px 0px rgba(255, 246, 229, 0.39);
        border-radius: 26px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 15px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #FFFFFF;
      }
    }
        .tips{
            width: calc(100% - 24px);
            margin: 0 auto;
            padding: 16px 12px;
            box-sizing: border-box;
            background: #FFFFFF;
            border-radius: 10px;
            p{
                font-size: 16px;
                font-family: PingFangSC, PingFang SC;
                font-weight: 500;
                color: #666666;
            }
            div{
                font-size: 14px;
                font-family: PingFangSC, PingFang SC;
                font-weight: 400;
                color: #999999;
            }
        }
        .videovip-num{
            width: calc(100% - 24px);
            margin: 10px auto 12px;
            background: #FFFFFF;
            border-radius: 10px;
            padding: 16px 12px;
            box-sizing: border-box;
            p{
                font-size: 16px;
                font-family: PingFangSC, PingFang SC;
                font-weight: 500;
                color: #666666;
                margin-bottom: 17px;
            }
            div{
                width: calc(100% - 4px);
                margin-left: 2px;
                height: 56px;
                background: #F6F6F6;
                padding: 0 16px;
                border-radius: 10px;
                display: flex;
                align-items: center;
                img{
                    width: 16px;
                    height: 16px;
                }
            }
            input{
                width: 100%;
                border: 0;
                height: 56px;
                font-size: 22px;
                background: #F6F6F6;
                font-family: PingFangSC, PingFang SC;
                font-weight: 500;
                color: #444444;
            }
        }
        .videovip-head{
            background: #FFFFFF;
            width: 100%;
            padding: 12px;
            padding-top: 10px;
            display: flex;
            align-items: center;
            img{
                width: 98px;
                height: 98px;
                margin-right: 14px;
            }
            div{
                font-size: 18px;
                font-family: PingFangSC, PingFang SC;
                font-weight: 600;
                color: #333333;
                p:nth-child(2){
                    font-size: 22px;
                    font-family: DINOffcPro, DINOffcPro;
                    font-weight: bold;
                    color: #6781FF;
                    margin-top: 13px;
                    display: flex;
                    align-items: center;
                    img{
                        width: 24px;
                        height: 24px;
                        margin-left: 3px;
                    }
                }
            }
        }
    }
    .voucher{
  width: 305px;
  background: #ffffff;
  margin-top: 150px;
  position: relative;
  padding-top: 60px;
  border-radius: 15px;
  padding-bottom: 30px;
  img:nth-child(1){
    width: calc(100% - 36px);
    position: absolute;
    top: -79px;
    left: 18px;
  }
  img:nth-child(2){
    width: 90px;
    position: absolute;
    top: -50px;
    left: calc(50% - 45px);
  }
  p:nth-child(3){
    font-size: 16px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #444444;
  }
  p:nth-child(4){
    width: calc(100% - 36px);
    height: 39px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: normal;
    color: #797AE5;
    display: flex;
    align-items: center;
    span{
      font-size: 24px;
      font-family: DINOffcPro-Medi, DINOffcPro;
      font-weight: normal;
      color: #797AE5;
      margin-top: 1px;
    }
    margin-bottom: 20px;
  }
//   p:nth-child(5){
//     font-size: 12px;
//     font-family: PingFangSC-Regular, PingFang SC;
//     font-weight: 400;
//     color: #888888;
//     margin-bottom: 10px;
//   }
  p:nth-child(5){
    width: 197px;
    height: 44px;
    background: linear-gradient(270deg, #6AC8FF 0%, #D199FF 100%);
    box-shadow: inset 0px 2px 0px 0px rgba(255, 246, 229, 0.39);
    border-radius: 22px;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #FFFFFF;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
  }
}
</style>