import axios from "axios";
import qs from "qs";
const caibei = {
  balance() {
    return axios.get("/api/ppy/usercenter/withdraw/balance");
  },
  exchangebalance() {
    return axios.get("/api/ppy/gamecenter/exchange/balance");
  },
  configs(type) {
    return axios.get("/api/ppy/gamecenter/exchange/configs", {
      params: {
        type: type,
      },
    });
  },
  requestExchange(configId) {
    return axios.post(
      "/api/ppy/gamecenter/exchange/requestExchange",
      qs.stringify({ configId })
    );
  },
  coinRecords(data) {
    return axios.get("/api/ppy/gamecenter/exchange/coinRecords", {
      params: {
        type: data.type,
        page: data.page,
        pageSize: 20,
      },
    });
  },
  coinRanking(data) {
    return axios.get("/api/ppy/gamecenter/exchange/coinRanking", {
      params: data,
    });
  },
  conchshopping() {
    return axios.get("/api/ppy/usercenter/conch/shopping");
  },
  //api/conch/buyItem
  conchbuyItem(goodsId,account){
      return axios.post(
        "/api/ppy/usercenter/conch/buyItem",{account},{params:{goodsId}}
      );
  },
  telPhoneBillcoinPay(data) {
    return axios.get("/api/ppy/usercenter/telPhoneBill/coinPay", {
      params: data,
    });
  },
  checkPhoneBind() {
    return axios.get("/api/ppy/usercenter/conch/checkPhoneBind");
  },
};
export default caibei;
